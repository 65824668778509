import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref } from "vue";
import { Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { useRoute, useRouter } from "vue-router";
import { getCartList } from "@/core/helpers/custom";
import { debounce } from "lodash";
import BaseField from "@/components/BaseField.vue";
import { requestRegisterOtpMobile, resendRegisterOtpMobile, } from "@/api/AuthMobileApi";
import { Actions } from "@/store/enums/StoreEnums";
export default defineComponent({
    name: "login",
    components: {
        Form: Form,
        BaseField: BaseField,
    },
    setup: function () {
        var _this = this;
        var store = useStore();
        var loading = ref({
            login: false,
            guest: false,
            nextPage: false,
            resend: false,
        });
        var route = useRoute();
        var router = useRouter();
        var displayTime = ref("0:00");
        var showing = ref({
            displayTime: false,
            displayAnotherLogin: true,
            page: 1,
            isExpire: false,
            validateCode: false,
            showResendOtp: true,
        });
        var globalTimerInterval;
        var isFromCheckout = ref(false);
        //Create form validation object
        var formLoginValidate = Yup.object().shape({
            phone_number: Yup.string().min(9).required("Required").label("Phone"),
        });
        var form = ref({
            phone_number: "",
            otp: null,
            uuid: null,
            message: null,
        });
        var gotoEmailRegister = function () {
            loading.value.guest = true;
            router.push({ name: "register" }).then(function () {
                loading.value.guest = false;
            });
        };
        function timer(countDownDate) {
            var timer = setInterval(function () {
                var now = new Date().getTime();
                var distance = countDownDate - now;
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                displayTime.value =
                    minutes + ":" + (seconds <= 9 ? "0" + seconds : seconds);
                if (distance < 0) {
                    showing.value.displayTime = false;
                    showing.value.isExpire = true;
                    clearInterval(timer);
                }
            }, 1000);
            return timer;
        }
        var page2 = function () { return __awaiter(_this, void 0, void 0, function () {
            var countDownDate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.nextPage = true;
                        countDownDate = new Date(new Date().getTime() + 60000 * 3).getTime();
                        if (globalTimerInterval) {
                            clearInterval(globalTimerInterval);
                        }
                        return [4 /*yield*/, requestRegisterOtpMobile(form.value)
                                .then(function (_a) {
                                var _b;
                                var data = _a.data;
                                form.value.uuid = (_b = data.data) === null || _b === void 0 ? void 0 : _b.uuid;
                                globalTimerInterval = timer(countDownDate);
                                showing.value.page = 2;
                                showing.value.displayAnotherLogin = false;
                                // alert("Otp is : " + data.data?.otp)
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value.nextPage = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var onChangeOtp = debounce(function (input) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        showing.value.validateCode = true;
                        if (!(input.length === 4)) return [3 /*break*/, 2];
                        return [4 /*yield*/, store
                                .dispatch(Actions.MOBILE_REGISTER, form.value)
                                .then(function () {
                                router.push({ name: "home" });
                            })
                                .catch(function () {
                                ("_");
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        setTimeout(function () {
                            showing.value.validateCode = false;
                        }, 250);
                        return [2 /*return*/];
                }
            });
        }); }, 500);
        var resendRegisterOtp = function () { return __awaiter(_this, void 0, void 0, function () {
            var countDownDate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.resend = true;
                        countDownDate = new Date(new Date().getTime() + 60000 * 2).getTime();
                        return [4 /*yield*/, resendRegisterOtpMobile(form.value)
                                .then(function (_a) {
                                var _b;
                                var data = _a.data;
                                form.value.uuid = (_b = data.data) === null || _b === void 0 ? void 0 : _b.uuid;
                                globalTimerInterval = timer(countDownDate);
                                showing.value.page = 2;
                                showing.value.displayAnotherLogin = false;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value.resend = false;
                        showing.value.showResendOtp = false;
                        return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () {
            isFromCheckout.value = route.params.gotoCheckout === "true";
            getCartList();
        });
        return {
            formLoginValidate: formLoginValidate,
            loading: loading,
            gotoEmailRegister: gotoEmailRegister,
            displayTime: displayTime,
            showing: showing,
            page2: page2,
            timer: timer,
            form: form,
            onChangeOtp: onChangeOtp,
            resendRegisterOtp: resendRegisterOtp,
        };
    },
});
